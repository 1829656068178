<template>
  <div class="module-product-number-alert-modal">
    <slot
      name="modal-header"
      :hasSeparator="false"
      :titleText="titleText"
      :titleStyle="modalTitleStyle"
    />
    <div class="content-wrapper" v-html="descriptionText" />
    <div class="button-row">
      <button-base v-if="primaryBtnText" :text="primaryBtnText" @clicked="handleBtnClick('primary')" />
    </div>
  </div>
</template>

<script>
import ButtonBase from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'SimpleModal',
  props: {
    titleText: {
      type: String,
    },
    descriptionText: {
      type: String,
    },
    primaryBtnText: {
      type: String,
    },
    modalTitleStyle: {
      type: Object,
    },
  },
  components: {
    ButtonBase,
  },
  methods: {
    handleBtnClick(key) {
      this.$emit('primaryButtonClicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.module-product-number-alert-modal {
  text-align: center;
  width: 450px;
  padding: 0 40px 25px;
}

.content-wrapper {
  color: $globalFontColor;
  margin: 15px 0 0;
  font-size: 14px;
  line-height: 17px;
}

.button-row {
  margin: 20px 0 0;
  display: flex;
  justify-content: space-between;

  .rounded-button {
    padding: 0;
  }
}
</style>
